<template>
  <div class="empty-content">
    <div><i class="f-icon icon-kong" />{{ msg }}</div>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n'

export default {
  props: {
    msg: {
      type: String,
      default: i18n.locale === 'ch' ? '暂无记录' : 'No record',
    },
  },
}
</script>

<style lang="scss">
.empty-content {
  padding: 50px 0 30px;
  color: #bcbccb;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: 6px;
  }
}
</style>
