<template>
  <li class="card-item" @click="handleDetail">
    <div class="img-wrapper">
      <img :src="info.img_logo" alt="" />
      <div class="tag-cate" v-if="this.info.cate_main === 3">直播</div>
      <div class="tag-cate" v-else-if="info.money == 0 && info.is_vip !== 1">
        免费
      </div>
    </div>
    <div class="title">
      {{ info.name }}
    </div>
    <template v-if="info.cate_main == 3 && info.type == 1">
      <div class="time">
        <i class="iconfont icon-time"></i>
        <span>{{
          (info.live_stime * 1000) | timeFormat('YYYY-MM-DD HH:mm:ss')
        }}</span>
      </div>
      <div class="free-btn" :class="{ disabled: !wantLive }">
        <div>{{ wantLive ? '免费报名' : '已结束' }}</div>
      </div>
    </template>
    <template v-else>
      <div class="tag">
        <Label :label="info.label_id" />
      </div>
      <div class="footer" v-if="info.chapter_cnt > 0">
        <span>
          {{ `${info.chapter_cnt || 0}课时` }}
        </span>
        <i class="iconfont icon-class-right"></i>
      </div>
      <div class="footer view" v-else>
        <span></span>
        <span>查看</span>
      </div>
    </template>

    <LiveDialog ref="live" />
  </li>
</template>

<script>
import Label from './label'
import LiveDialog from './liveDialog'

export default {
  components: {
    Label,
    LiveDialog
  },
  props: {
    info: {
      type: Object,
      defualt: () => ({})
    }
  },
  computed: {
    wantLive() {
      try {
        if (new Date(this.info.live_stime).getTime() > new Date().getTime()) {
          return true
        }
      } catch (e) {}

      return false
    }
  },
  methods: {
    handleDetail() {
      // 直播课
      if (Number(this.info.cate_main) === 3) {
        if (this.wantLive) {
          return this.$refs.live.open(this.info)
        } else if (Number(this.info.type) === 1) {
          return false
          // return this.$router.push({
          //   name: 'chapterDetailLive',
          //   query: {
          //     skillId: this.info.id
          //   }
          // })
        }
      }
      switch (Number(this.info.type)) {
        case 1:
          {
            this.$router.push({
              name: 'IELTSchapterDetailText',
              query: {
                skillId: this.info.id
              }
            })
          }
          break
        case 2:
          {
            this.$router.push({
              name: 'IELTSchapterDetail',
              query: {
                skillId: this.info.id,
                type: 1
              }
            })
          }
          break
        case 3:
          {
            this.$router.push({
              name: 'IELTScourseChapter',
              query: {
                cateId: this.info.cate_main,
                skillId: this.info.id
              }
            })
          }
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card-item {
  width: 228px;
  margin: 12px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-8px);
  }
  .img-wrapper {
    position: relative;
    > img {
      width: 208px;
      height: 112px;
      object-fit: cover;
      border-radius: 5px;
    }
    .tag-cate {
      position: absolute;
      min-width: 44px;
      height: 17px;
      top: 6px;
      right: 6px;
      color: #f44336;
      font-size: 12px;
      background-color: #fff;
      border-radius: 10px;
      text-align: center;
    }
  }
  .title {
    font-size: 14px;
    color: #1f1f40;
    font-weight: bold;
    margin-top: 10px;
    height: 42px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .tag {
    margin-top: 6px;
  }
  .footer {
    margin-top: 10px;
    border-top: 1px solid #ebebf2;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    &.view {
      > span {
        color: #1f1f40;
        font-size: 13px;
      }
    }
    > span {
      color: #9c9c9c;
      font-size: 14px;
    }
  }
  .time {
    margin-top: 8px;
    font-size: 12px;
    color: #ff606d;
    > i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .free-btn {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    &.disabled {
      > div {
        background-color: #bfbfbf;
      }
    }
    > div {
      padding: 6px 0;
      width: 140px;
      text-align: center;
      background-color: #3b86ff;
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
