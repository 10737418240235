<template>
  <div class="wrap">
    <div class="space left"></div>
    <div class="my-skill">
      <Nav />

      <div class="content">
        <div class="block">
          <div class="title">已购买</div>
          <Buy />
        </div>

        <div class="block">
          <div class="title">浏览记录</div>
          <History />
        </div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import Nav from '../SkillIndex/nav'

import Buy from './buy'
import History from './history'

export default {
  components: {
    Nav,
    Buy,
    History
  }
}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  min-height: calc(100vh - 66px);
  .space {
    flex: 1;
    &.left {
      background-color: #f8f8f8;
    }
  }
  .my-skill {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .content {
      flex: 1;
      margin-left: 46px;
      margin-top: 0;
      .block {
        margin-top: 30px;
        .title {
          color: #1f1f40;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
