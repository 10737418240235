<template>
  <el-dialog
    :visible.sync="visible"
    width="600px"
    center
    custom-class="body-no-padding"
  >
    <div class="dialog-wrapper">
      <div class="top">
        <p class="title">立刻报名免费参加直播课程</p>
        <p class="text">
          本课程将在ZOOM平台进行直播，报名后您将在开课前收到站内消息提醒
        </p>
        <div class="button" @click="handleLiveDetail">
          <button>一键报名</button>
        </div>
      </div>

      <div class="bottom">
        <div class="left">
          <img :src="wechatImg" alt="" />
        </div>
        <div class="right">
          <p class="more">如果你还想...</p>
          <div class="three-want">
            <p class="text">与老师互动，向老师提问获得答疑</p>
            <p class="text">加入备考群，与小伙伴共同进步</p>
            <p class="text">领取各类备考文件，讲解视频</p>
          </div>
          <div class="images">
            <div class="img-wrapper">
              <img :src="helperInfo.site_val" alt="" />
            </div>
            <span>添加小助手立刻获得</span>
          </div>
        </div>
      </div>

      <div class="footer">
        <span>关闭本窗口跳转报名说明页</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import wechatImg from '@/assets/class/wechat-jilu.png'
import { apisignUp } from '@/api/IELTScourse.js'

export default {
  data() {
    return {
      visible: false,
      info: {}
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    }),
    wechatImg() {
      return wechatImg
    }
  },
  props: {},
  methods: {
    open(info) {
      this.info = info
      this.visible = true
    },

    handleLiveDetail() {
      apisignUp({ course_id: this.info.id }).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          setTimeout(() => {
            this.$router.push({
              name: 'chapterDetailLive',
              query: {
                skillId: this.info.id
              }
            })
          }, 500)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-wrapper {
  .top {
    padding: 36px 0 20px 0;
    background: #3b86ff;
    text-align: center;
    .title {
      font-size: 32px;
      color: #ffffff;
      font-weight: 400;
      margin-bottom: 8px;
      margin-left: 16px;
    }
    .text {
      font-size: 14px;
      color: #9dc3ff;
      margin-bottom: 20px;
    }
    .button {
      > button {
        outline: none;
        border: none;
        width: 114px;
        height: 36px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 50px;
        font-size: 18px;
        color: #3b86ff;
      }
    }
  }
  .bottom {
    background: #ffffff;
    display: flex;
    .left {
      margin-left: 20px;
      margin-top: 10px;
    }
    .right {
      margin-top: 20px;
      margin-left: 20px;
      color: #1f1f40;
      .more {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .three-want {
        .text {
          margin-bottom: 8px;
          position: relative;
          margin-left: 12px;
          &:after {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #3b86ff;
            position: absolute;
            left: -12px;
            top: 8px;
          }
        }
      }
      .images {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img-wrapper {
          margin-bottom: 6px;
          > img {
            width: 100px;
          }
        }
        > span {
          font-size: 12px;
          color: #8f8f9f;
        }
      }
    }
  }
  .footer {
    padding-bottom: 10px;
    text-align: center;
    > span {
      font-size: 12px;
      color: #8f8f9f;
    }
  }
}
::v-deep .body-no-padding {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__close {
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
